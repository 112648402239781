import { ForumAssociationInput } from '../../../../types/graphqlTypes';
import ActivityLink from '../model/ActivityLink';
import { AssociationType, FileType } from '../model/Enums';
import FileHeader from '../model/FileHeader';

const isNumber = (n: string | number | undefined): boolean =>
    n !== undefined && !isNaN(parseFloat(String(n))) && isFinite(Number(n));

export default class AssociationUtils {

    static getAssociationInputs(files: FileHeader[], links: ActivityLink[]): ForumAssociationInput[] {
        let result: ForumAssociationInput[] = new Array<ForumAssociationInput>();

        files.forEach((photo, index) =>
            result.push(AssociationUtils.buildAssociation(photo.id, AssociationUtils.getPhotoAssociationType(photo), index, photo.name, photo.type)));
        links.forEach((link, index) =>
            result.push(AssociationUtils.buildAssociation(link.FileId ?? link.FolderId, AssociationUtils.getLinkAssociationType(link), index + files.length, link.Title, link.FileType)));
        return result;
    }

    static getPhotoAssociationType(photo: FileHeader): AssociationType {
        return photo.type.toLowerCase().indexOf("image") == 0 ? AssociationType.AttachedPhoto : AssociationType.AttachedFile;
    }

    static getLinkAssociationType(link): AssociationType {
        if (link.Category == "Document") {
            return link.FileId ? AssociationType.GroupDocument : AssociationType.GroupDocumentFolder;
        } else {
            return link.FileId ? AssociationType.GroupMedia : AssociationType.GroupMediaFolder;
        }
    }

    static buildAssociation(associatedItemId: string, associationType: AssociationType, priority: number, displayName: string, itemType: string | number): ForumAssociationInput {
        return {
            associatedItemId,
            associationType,
            priority,
            displayName,
            itemType,
        } as ForumAssociationInput;
    }

    static buildFile(association: ForumAssociationInput): FileHeader {
        return new FileHeader(association.associatedItemId, association.displayName, isNumber(association.itemType) ? FileType[Number(association.itemType)] : association.itemType);
    }

    static buildLink(association: ForumAssociationInput): ActivityLink {
        return new ActivityLink(association.associatedItemId, association.displayName, association.associationType, isNumber(association.itemType) ? FileType[Number(association.itemType)] : association.itemType);
    }


}
