import React, {
    FC, useState,
} from "react";
import _ from 'lodash';
import BtnUpload from "../../../core/components/BtnUpload";
import AppDelegate from "../../../core/AppDelegate";
import { ForumAssociationInput } from "../../../../types/graphqlTypes";
import { FileFactory } from "../../../core/models/File";
import Localisation from "../../../core/resources/Localisation";
import PromptActions from "../../prompt/actions/PromptActions";
import { AssociationType } from "../model/Enums";
import AssociationUtils from "./AssociationUtils";

interface IForumUploadProps {
    associations: ForumAssociationInput[] | null,
    _onFilesChanged: any,
    groupId: string,
}

export const ForumUpload: FC<IForumUploadProps> = (props: IForumUploadProps) => {

    const isLink = (association: ForumAssociationInput) => association.associationType == AssociationType.GroupDocumentFolder ||
        association.associationType == AssociationType.GroupDocument ||
        association.associationType == AssociationType.GroupMediaFolder ||
        association.associationType == AssociationType.GroupMedia;

    const isFile = (association: ForumAssociationInput) => association.associationType == AssociationType.AttachedFile ||
        association.associationType == AssociationType.AttachedPhoto ||
        association.associationType == AssociationType.EmbedlyResult;

    const [links, setLinks] = useState(_.filter(props.associations, isLink).map(association => AssociationUtils.buildLink(association)));

    // TODO: photos are redundant?
    const [attachedPhotos, setAttachedPhotos] = useState(_.filter(props.associations, isFile).map(association => AssociationUtils.buildFile(association)));
    const [files, setFiles] = useState(_.filter(props.associations, isFile).map(association => AssociationUtils.buildFile(association)));
    const [active, isActive] = useState(false);

    /*
    const onSetFiles = (f) => {
        // setFiles(f.filter(file => !file.error));
        setFiles(f);
        setAttachedPhotos(_.filter(attachedPhotos, photo => _.some(f, file => photo.id == file.id)));
    }

    const onSetLinks = (l) => {
        setLinks(l);
    }

    function onRemoveLink(link, e): MouseEventHandler<HTMLAnchorElement> {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        setLinks(links.filter(i => (!i.FolderId || i.FolderId != link.FolderId) && (!i.FileId || i.FileId != link.FileId)));
        return;
    }
    */

    /**
     * The photo uploaded event
     * @param {*}	result	- The server response
     * @private
     */
    function _onFileUploaded(result, domFiles) {

        let file = FileFactory.fileFromObject(result);

        if (file == null) {
            PromptActions.displayPrompt(Localisation.localisedStringFor('Error'), Localisation.localisedStringFor('ErrorUnexpectedResponse'));
            return;
        }

        // Remove any embedly stuff
        // GMS TODO: this._removeEmbedly();

        let photos = [...attachedPhotos, file].sort((left, right) => {
            let l = domFiles.findIndex(i => i.id == left.id);
            let r = domFiles.findIndex(i => i.id == right.id);

            return l < r ? -1 : 0;
        });

        setAttachedPhotos(photos);

    }

    /**
     * Handle upload failed
     * @param errMessage
     * @private
     */
    function _onFileUploadFailed(err) {
        PromptActions.displayFriendlyErrorPrompt(err);
    }

    const onUpdateFileList = (e) => {
        setFiles(e);
        props._onFilesChanged(e, links);
    }

    const onUpdateLinks = (e) => {
        setLinks(e);
        props._onFilesChanged(files, e);
    }

    return (
        <BtnUpload url={AppDelegate.appSettings.fileUploadUrl} btnStyle={null}
            title="Add/Edit File" label="Add/Edit File" className="create-post__types-type" btnIconStyle="fn-icon fn-icon-upload"
            allowMultiple={true} allowRemove={true} active={active} amountLimit={10}
            category='Group'
            selectedGroupOrEvent={props.groupId}
            canAddExisting={true}
            showNotify={false}
            updateFileList={onUpdateFileList}
            uploadFormData={[{ name: 'Type', value: 'GroupPost' }]}
            links={links}
            files={files}
            onUpdateLinks={onUpdateLinks}
            attachedPhotos={attachedPhotos}
            onFileUploadCompleteImmediate={_onFileUploaded}
            onFileUploadFailed={_onFileUploadFailed}>
            <span className="fn-icon fn-icon-upload"></span>Add/Edit File
        </BtnUpload>
    );
}

export default ForumUpload;