import React, {
    FC,
    useEffect,
    useState,
    useRef,
    MutableRefObject,
    MouseEventHandler,
} from "react";
import _ from 'lodash';

import {
    useMutation,
} from "@apollo/client";
import {
    forumDiscussionResult,
    forumReplyResult,
    FORUM_SAVE_REPLY_MUTATIONVariables,
    forumResult_topics,
    association,
    FORUM_UPDATE_REPLY_STATEVariables,
} from "../../../../types/graphqlTypes";

const forumsGql = require('../../../../gql/forums.gql');

import AckButton from "./AckButton";
import QuoteButton from "./QuoteButton";
import SvgIcon from '../../../icons';
import moment from 'moment';

import { DATE_TIME_STANDARD_RENDER_FORMAT } from "../../../core/utils/DateTimeUtils";
import RichTextEditor from "../../../core/components/richtext/RichTextEditor";
import { ConfirmationModal } from "./ConfirmationModal";
import { UpdateReply } from "./UpdateReply";
import { UserRightsType } from "../model/UserRightsType";
import PromptActions from "../../prompt/actions/PromptActions";
import TagUtils from "../../../core/utils/TagUtils";
import AssociatedFiles from "./AssociatedFiles";
import ReactTooltip from 'react-tooltip';
import ReportItem from '../../../modules/reportabuse/components/ReportItem';
import DateTimeUtils from "../../../core/utils/DateTimeUtils";
const renderDateTime = DateTimeUtils.renderDateTime;

interface IDiscussionReplyProps {
    rte: React.RefObject<RichTextEditor>,
    discussionResult: forumDiscussionResult,
    discussionReplyResult: forumReplyResult,
    setDiscussion: (e) => void,
    newReplyId: any,
    groupId: string,
    onSelectDiscussion: (discussionResult: forumDiscussionResult, topicResult: forumResult_topics) => void,
    onCancel: () => void,
    onClick: (e) => MouseEventHandler<HTMLAnchorElement>,
    resortReplies: () => void,
    highlightedReply: { replyId },
    setHighlightedReply: ({ replyId }) => void,
    activeReply: { replyId },
    setActiveReply: ({ replyId }) => void,
    refetchDiscussion: () => void
}

export const DiscussionReply: FC<IDiscussionReplyProps> = (props: IDiscussionReplyProps) => {
    const [saveForumReply] = useMutation(forumsGql.FORUM_SAVE_REPLY_MUTATION);
    const [updateDiscussionReplyState] = useMutation(forumsGql.FORUM_UPDATE_REPLY_STATE);
    const topRef = useRef(null);
    const manageMenuRef = useRef(null);
    const [showManageMenu, setShowManageMenu] = useState(false);
    const onClickManageMenu = () => setShowManageMenu(!showManageMenu);
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [displayReplyEdit, setDisplayReplyEdit] = useState(false);
    const [showHowToQuote, setShowHowToQuote] = useState(false);
    const [isHighlighted, setIsHighlighted] = useState(false);
    const [isNewReply, setIsNewReply] = useState(props.newReplyId == props.discussionReplyResult.reply.replyId);
    const [isActive, setIsActive] = useState(props.activeReply.replyId == props.discussionReplyResult.reply.replyId);
    const editedText = (props.discussionReplyResult.reply.lastModifiedDate == props.discussionReplyResult.reply.createdDate) ? "" : " (edited)";
    const contentDivRef = React.useRef<HTMLDivElement>(null);
    const [showReportModal, setShowReportModal] = useState(false);

    useEffect(() => {
        setIsActive(props.activeReply.replyId == props.discussionReplyResult.reply.replyId);
    }, [props.activeReply]);

    useEffect(() => {
        highlightMe(props.highlightedReply.replyId == props.discussionReplyResult.reply.replyId, true);
    }, [props.highlightedReply.replyId]);

    useEffect(() => {
        setIsNewReply(props.newReplyId == props.discussionReplyResult.reply.replyId);
        if (isNewReply) {
            highlightMe(true, true);
        }
    }, [props.newReplyId]);

    useEffect(() => {
        if (displayReplyEdit) setIsActive(false);
    }, [displayReplyEdit]);

    const onToggleFiles = () => {
        if (isActive) {
            setIsActive(false);
        } else {
            props.setActiveReply({ replyId: props.discussionReplyResult.reply.replyId });
            setIsActive(true);
        }
    }

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu, then close the menu
            if (showManageMenu && manageMenuRef.current && !manageMenuRef.current.contains(e.target)) {
                setShowManageMenu(false);
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [showManageMenu]);

    useEffect(() => {
        props.resortReplies();
    }, [props.discussionReplyResult]);

    const highlightMe = (highlight: boolean, scroll: boolean) => {
        if (highlight) {
            props.setHighlightedReply({ replyId: props.discussionReplyResult.reply.replyId });
            if (scroll) {
                // scroll to top of updated reply
                topRef.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
            }
        }
        setIsHighlighted(highlight);
    }

    const onEditDiscussion = () => {
        setDisplayReplyEdit(true);
    }

    const onDeleteDiscussion = (e) => {
        setDisplayDeleteModal(true);
    }

    const onReportDiscussion = (e) => {
        setShowReportModal(true);
    }

    const onDeleteModalConfirm = () => {
        modifyDiscussionReplyState(false);
        setDisplayDeleteModal(false);
        props.setHighlightedReply({ replyId: "" });
    }

    const onSetReply = (discussionReplyResult) => {
        var discussionResultNew = {
            ...props.discussionResult,
            replies: [...props.discussionResult.replies.filter(
                replyResult => replyResult.reply.replyId != discussionReplyResult.reply.replyId
            ), discussionReplyResult]
        }
        props.setDiscussion(discussionResultNew);
    }

    const modifyDiscussionReplyState = (newIsEnabled) => {

        updateDiscussionReplyState({
            variables: {
                discussionId: props.discussionResult.discussion.discussionId,
                replyId: props.discussionReplyResult.reply.replyId,
                isEnabled: newIsEnabled
            } as FORUM_UPDATE_REPLY_STATEVariables
        }).then((saveDiscussionReplyResult) => {
            // update frontend state
            onSetReply(saveDiscussionReplyResult.data.updateForumReplyState);

        }).catch(err => {
            PromptActions.displayFriendlyErrorPrompt(err);
        }).finally();
    }

    const onDeleteModalClose = () => {
        setDisplayDeleteModal(false);
    }

    const onEditReplyClose = () => {
        setDisplayReplyEdit(false);
    }

    const closeReportModal = () => {
        setShowReportModal(false);
    }

    const onReported = () => {
        if (props.refetchDiscussion) {
            props.refetchDiscussion();
        }
    }

    const onUpdateReply = (content: string, associations: association[]) => {
        saveForumReply({
            variables: {
                discussionId: props.discussionResult.discussion.discussionId,
                discussionPostVersion: props.discussionResult.headPost.draftVersion,
                replyId: props.discussionReplyResult.reply.replyId,
                content: content,
                associations: associations
            } as FORUM_SAVE_REPLY_MUTATIONVariables
        }).then((savedReply) => {
            // update state
            onSetReply(savedReply.data.saveForumReply);
            highlightMe(true, true);
        }).catch(err => {
            PromptActions.displayFriendlyErrorPrompt(err);
        }).finally();
    }

    const getSelection = (): string | null => {
        let selection = TagUtils.getSelectionTextAndContainerElement('div', 'reply-content');
        
        if(!Boolean(selection.text) || contentDivRef.current !== selection.containerElement) {
            // show how-to-quote message
            setShowHowToQuote(true);
            highlightMe(true, false);
            return null;
        } else if (showHowToQuote) {
            setShowHowToQuote(false);
        }

        return selection.text;
    }

    const onCloseHowToQuote = (e) => {
        setShowHowToQuote(false);
    }

    return (
        <div ref={topRef} className={`thread fn-service-border-colour scroll-to ${isActive ? ' active-post' : ''}${isHighlighted ? " highlight-reply" : ""}`}>
            <div className="no-mobile"><ReactTooltip place="right" backgroundColor="#738089" className="hover-text" /></div>
            <div className={`discussion-reply scroll-to ${props.discussionReplyResult.reply.isEnabled ? "" : " disabled"}`}>
                <ConfirmationModal displayModal={displayDeleteModal} heading="Delete reply"
                    reasonMessage="Reason for deleting this reply"
                    confirmMessage="Are you sure you want to delete your reply?"
                    userRights={props.discussionReplyResult.userRights} title={props.discussionResult?.headPost.subject}
                    onConfirm={onDeleteModalConfirm} onRequestClose={onDeleteModalClose} />

                <div className="fn-flex">
                    <div className="author-card-avatar">
                        <a href={`/viewprofile?Id=${props.discussionReplyResult.createdBy.id}`}>
                            <img className="bg-primary"
                                src={`/api/v1/image?id=${props.discussionReplyResult.createdBy.avatarId}&amp;width=54&amp;height=54`} />
                        </a>
                    </div>
                    <div className="full-width discussion-heading">
                        <div className="row">
                            <div className="small-8 columns">
                                <a href={`/viewprofile?Id=${props.discussionReplyResult.createdBy.id}`}
                                    className="title">{props.discussionReplyResult.createdBy.name}</a>
                            </div>
                            <div className="small-8 columns">
                                <span>{renderDateTime(moment(props.discussionReplyResult.reply.lastModifiedDate))}{editedText}</span>
                            </div>
                            <div className="small-16 columns">
                                {displayReplyEdit &&
                                    <UpdateReply discussionReplyResult={props.discussionReplyResult} onUpdateReply={onUpdateReply}
                                        groupId={props.groupId} onClick={props.onClick}
                                        key={props.discussionReplyResult.reply.replyId}
                                        onRequestClose={onEditReplyClose} title={props.discussionResult.headPost.subject}
                                    />
                                }
                                {!displayReplyEdit &&
                                    <div dir="auto" className="reply-content small-12 columns" ref={contentDivRef}>
                                        {<span dangerouslySetInnerHTML={{ __html: props.discussionReplyResult.reply.content }} /> ??
                                            <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />}
                                    </div>
                                }
                            </div>
                        </div>
                        {props.discussionReplyResult.reply.isEnabled &&
                            <div className="reply-actions">
                                <div className="small-3 columns">
                                    {props.discussionResult.discussion.isOpen &&
                                        <AckButton discussionResult={props.discussionResult} replyResult={props.discussionReplyResult}
                                            setDiscussion={props.setDiscussion} setReply={onSetReply} />
                                    }
                                    {!props.discussionResult.discussion.isOpen &&
                                        <span>&nbsp;</span>
                                    }
                                </div>
                                <div className="small-3 columns">
                                    {props.discussionReplyResult.totalAcks} {props.discussionReplyResult.totalAcks == 1 ? " Ack" : " Acks"}
                                </div>
                                <div className="small-5 columns no-mobile">
                                    {props.discussionResult.discussion.isOpen &&
                                        <QuoteButton getSelection={getSelection} rte={props.rte} discussionResult={props.discussionResult} replyResult={props.discussionReplyResult} />
                                    }
                                    {!props.discussionResult.discussion.isOpen &&
                                        <span>&nbsp;</span>
                                    }
                                </div>
                                { false &&
                                <div className="small-2 columns">
                                    <a href=""><SvgIcon icon="icon-bookm-ark-o" width={21} height={15} /></a>
                                </div>
                                }
                                {(props.discussionReplyResult.userRights != UserRightsType.None || props.discussionReplyResult.canReport) &&
                                    <div className="small-8 large-1 columns">
                                        { showReportModal &&
                                        <ReportItem key="reportmodal" itemType='Reply'
                                            itemId={props.discussionReplyResult.reply.replyId}
                                            itemName={props.discussionReplyResult.createdBy.name} onRequestsClose={closeReportModal} onReported={onReported} />
                                        }
                                        <div onClick={onClickManageMenu} className="toggle" data-tip="Manage menu"><span className="fn-icon fn-icon-arrow-down"></span>
                                            <ul ref={manageMenuRef} className={`vertical-menu menu-toggle ${showManageMenu ? 'active' : 'inactive'}`}>
                                                { props.discussionReplyResult.canReport
                                                    && <li><a onClick={onReportDiscussion}><SvgIcon icon="icon-report" /> Report reply</a></li>
                                                }
                                                { props.discussionReplyResult.userRights != UserRightsType.None && props.discussionResult.discussion.isOpen
                                                    && <li><a onClick={onEditDiscussion}><SvgIcon icon="icon-edit" /> Edit reply</a></li>
                                                }
                                                { props.discussionReplyResult.userRights != UserRightsType.None
                                                    && <li><a onClick={onDeleteDiscussion}><SvgIcon icon="icon-bin" /> Delete reply</a></li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                }
                                <div className="small-3 large-2 columns">
                                    {(props.discussionReplyResult.associations && props.discussionReplyResult.associations.length > 0) &&
                                        <span className={`float-right${isActive ? ' fn-service-colour' : ''}`} onClick={onToggleFiles} data-tip="View related files">
                                            <SvgIcon icon="icon-folder" className="fn-icon-large fn-icon-top-align" />
                                            <SvgIcon icon="icon-arrow-left" className="fn-icon-large fn-icon-space no-mobile" />
                                        { isActive 
                                            ? <SvgIcon icon="icon-arrow-up" className="fn-icon-space mobile-only" />
                                            : <SvgIcon icon="icon-arrow-down" className="fn-icon-space mobile-only" />
                                        }
                                        </span>
                                    }
                                </div>
                                
                                { showHowToQuote &&
                                    <div className="ui-tooltip">
                                        <button className="button close" onClick={onCloseHowToQuote}><SvgIcon icon="icon-delete" /></button>
                                        In the reply above, please select the text you want to quote then press
                                        <QuoteButton getSelection={getSelection} rte={props.rte} discussionResult={props.discussionResult} replyResult={props.discussionReplyResult} />
                                    </div>
                                }
                            </div>
                        }

                    </div>
                </div>
            </div>
            { isActive &&
            <div className="fade-in">
                <div className="related-files">
                    <AssociatedFiles discussionReplyResult={props.discussionReplyResult} />
                </div>
            </div>
            }
        </div>
    )
}

export default DiscussionReply;
