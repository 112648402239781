import React, {
    FC,
    MouseEventHandler,
    useState,
} from "react";
import _ from 'lodash';
import {
    association,
    forumReplyResult,
} from "../../../../types/graphqlTypes";
import { UserRightsType } from "../model/UserRightsType";
import RichTextEditor, { RichTextEditorMode } from "../../../core/components/richtext/RichTextEditor";
import { ConfirmationModal } from "./ConfirmationModal";
import DisplayFileList from "./DisplayFileList";
import ForumUpload from "./ForumUpload";
import AssociationUtils from "./AssociationUtils";

interface IUpdateReplyProps {
    discussionReplyResult: forumReplyResult,
    title: string,
    onUpdateReply: (content: string, associations: association[], reason?: string) => void,
    onRequestClose: () => void,
    groupId: string,
    onClick: (e) => MouseEventHandler<HTMLAnchorElement>,
}

export const UpdateReply: FC<IUpdateReplyProps> = (props: IUpdateReplyProps) => {
    const [content, setContent] = useState(props.discussionReplyResult.reply.content);
    const [displayEditModal, setDisplayEditModal] = useState(false);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [associations, setAssociations] = useState(props.discussionReplyResult ? props.discussionReplyResult.associations : null);

    var contentChanged = false;
    var filesChanged = false;

    const userPreference = {
        ShowRteToolbarWide: true,
        ShowRteToolbarNarrow: false
    }

    const onChangeContent = (value, e) => {
        setContent(value);
        contentChanged = (value !== props.discussionReplyResult.reply.content);
        setIsSaveEnabled(filesChanged || contentChanged);
    }

    const onSaveChanges = (e) => {
        e.preventDefault();

        if (props.discussionReplyResult.userRights != UserRightsType.Author) {
            setDisplayEditModal(true);
        } else {
            updateReply();
        }
    }

    const onFilesChanged = (files: any[], links: any[]) => {
        let validFiles = _.filter(files,(file) => file.error === undefined);
        setAssociations(AssociationUtils.getAssociationInputs(validFiles, links));
        filesChanged = true;
        setIsSaveEnabled(true);
    }

    const updateReply = () => {
        props.onUpdateReply(content, associations);
        props.onRequestClose();
    }

    const onEditModalConfirm = () => {
        updateReply();
        setDisplayEditModal(false);
        props.onRequestClose();
    }

    const onEditModalClose = (e) => {
        setDisplayEditModal(false);
        props.onRequestClose();
    }

    return (
        <div>
            {props.discussionReplyResult && (props.discussionReplyResult.userRights != UserRightsType.Author) &&
                <ConfirmationModal displayModal={displayEditModal} heading="Edit reply"
                    reasonMessage="Reason for editing this reply"
                    confirmMessage="Are you sure you want to update this reply?"
                    userRights={props.discussionReplyResult.userRights} title={props.title}
                    onConfirm={onEditModalConfirm} onRequestClose={onEditModalClose} />
            }
            <div className="inline-edit form-fields">
                <div className="form-fields">
                    <RichTextEditor value={content} onValueChange={onChangeContent} userPreference={userPreference}
                        readOnly={false} spellcheck={true} mode={RichTextEditorMode.ActivityPost} />
                </div>

                {(associations && associations.length > 0) &&
                    <div className="form-field">
                        <DisplayFileList associations={associations} />
                    </div>
                }

                <div className="form-field">
                    <div className="buttons">
                        <div className="inline">
                            <ForumUpload associations={associations} _onFilesChanged={onFilesChanged} groupId={props.groupId}     >
                            </ForumUpload>
                            &nbsp;
                            <button className="btn btn-default" onClick={props.onRequestClose}>Cancel</button>
                            &nbsp;
                            <button className="btn btn-small btn-primary" disabled={!isSaveEnabled}
                                onClick={onSaveChanges}>Save Changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}