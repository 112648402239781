import React, {
    FC,
    MouseEventHandler,
    useState,
    useRef,
    useEffect
} from "react";
import { useLocation } from "react-router-dom";

import {
    useLazyQuery,
    useMutation,
} from "@apollo/client";

import ForumTabs from "./ForumTabs";

const forumsGql = require('../../../../gql/forums.gql');

import DiscussionReply from "./DiscussionReply";
import AckButton from "./AckButton";
import QuoteButton from "./QuoteButton";
import DisplayFileList from "./DisplayFileList"
import { SortReplies } from "./SortReplies";
import { ReplySortOrder } from "../model/ReplySortOrder";
import AppDelegate from "../../../core/AppDelegate";
import RichTextEditor, { RichTextEditorMode } from "../../../core/components/richtext/RichTextEditor";
import ReportItem							from '../../../modules/reportabuse/components/ReportItem';
import {
    forumResult_topics,
    forumDiscussionResult,
    FORUM_SAVE_REPLY_MUTATIONVariables,
    FORUM_UPDATE_DISCUSSION_STATEVariables,
    getMyLatestBookmarks_myLatestBookmarks,
    association,
    saveDiscussionActivityResult,
} from "../../../../types/graphqlTypes";

import { BookmarkToggle }					from '../../../core/components/BookmarkToggle';

import SvgIcon from '../../../icons';
import moment from 'moment';
import moment_timezone from 'moment-timezone';
import { UserRightsType } from "../model/UserRightsType";

import { DATE_TIME_STANDARD_RENDER_FORMAT } from "../../../core/utils/DateTimeUtils";


import PromptActions from "../../prompt/actions/PromptActions";

import { ConfirmationModal } from "./ConfirmationModal";
import { Quill } from 'react-quill';
import { QuoteBlot } from "../model/QuoteBlot";
import { Clock } from "../action/Clock";
import { UpdateDiscussion } from "./UpdateDiscussion";
import ForumUpload from "./ForumUpload";
import AssociationUtils from "./AssociationUtils";
import _ from "lodash";
import TagUtils from "../../../core/utils/TagUtils";
import ReactTooltip from 'react-tooltip'

import DateTimeUtils from "../../../core/utils/DateTimeUtils";
const renderDateTime = DateTimeUtils.renderDateTime;

const getMyLatestBookmarksGql = require("../../../../gql/getMyLatestBookmarks.gql");

Quill.register(QuoteBlot);

const scrollToTop = () => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 160);
        return () => { }
    }, [location]);

    return;
}

interface IViewDiscussionProps {
    discussionResult: forumDiscussionResult,
    topicResult: forumResult_topics,
    replySortOrder: typeof ReplySortOrder,
    groupId: string,
    topicResults: forumResult_topics[],
    onChangeReplySortOrder: (e) => void,
    setDiscussion: (e) => void,
    onSelectDiscussion: (discussionResult: forumDiscussionResult, topicResult: forumResult_topics) => void,
    onCancel: () => void,
    onClick: (e) => MouseEventHandler<HTMLAnchorElement>,
    refetch: () => void
}

export const ViewDiscussion: FC<IViewDiscussionProps> = (props: IViewDiscussionProps) => {
    const [saveForumReply] = useMutation(forumsGql.FORUM_SAVE_REPLY_MUTATION);
    const [updateForumDiscussionState] = useMutation(forumsGql.FORUM_UPDATE_DISCUSSION_STATE);
    const [saveDiscussionActivityMutation] = useMutation(forumsGql.FORUM_SAVE_DISCUSSION_ACTIVITY_MUTATION);
    const [content, setContent] = useState("");
    const rte = React.createRef<RichTextEditor>();
    const headPostRef = useRef(null);
    const postReplyRef = useRef<null | HTMLDivElement>(null);
    const [newReplyId, setNewReplyId] = useState(null);
    const manageMenuRef = useRef(null);
    const [showReportModal, setShowReportModal] = useState(false);
    const [showManageMenu, setShowManageMenu] = useState(false);
    const [showHowToQuote, setShowHowToQuote] = useState(false);
    const [isOpen, setIsOpen] = useState(props.discussionResult.discussion.isOpen);
    const [isEnabled, setIsEnabled] = useState(props.discussionResult.discussion.isEnabled);
    const onClickManageMenu = () => setShowManageMenu(!showManageMenu);
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [displayDiscussionEdit, setDisplayDiscussionEdit] = useState(false);
    const [activeReply, setActiveReply] = useState({ replyId: "" });
    const [highlightedReply, setHighlitedReply] = useState({ replyId: "" });
    const [isActiveHeadPost, setIsActiveHeadPost] = useState(false);
    const editedText = (props.discussionResult.discussion.lastModifiedDate == props.discussionResult.discussion.createdDate) ? "" : " (edited)";
    const [associations, setAssociations] = useState<association[] | null>(null);
    const [isReported, setIsReported] = useState(props.discussionResult.isReported);

    const [getMyLatestBookmarks, { loading, data, error, networkStatus, refetch }] = useLazyQuery(getMyLatestBookmarksGql, {
        fetchPolicy: "network-only"
    });
    
    const bookmarks: Array<getMyLatestBookmarks_myLatestBookmarks> = data?.myLatestBookmarks;
    const bookmark: getMyLatestBookmarks_myLatestBookmarks | null = _.find(bookmarks, b => {
            return b.discussion && (b.discussion.discussion.discussionId == props.discussionResult.discussion.discussionId);
        });

    useEffect(() => {
        saveDiscussionActivity();
        getMyLatestBookmarks();
    }, []);

    const userPreference = {
        ShowRteToolbarWide: true,
        ShowRteToolbarNarrow: false
    }

    const [isSaveEnabled, setIsSaveEnabled] = useState(false);

    useEffect(() => {
        setIsSaveEnabled(rte.current != null && !rte.current.isEmptyContent());
    }, [content]);

    const saveDiscussionActivity = () => {
        saveDiscussionActivityMutation({
            variables: {
                discussionId: props.discussionResult.discussion.discussionId,
            } as saveDiscussionActivityResult
        }).then((saveDiscussionActivityResult) => {
//            console.log(saveDiscussionActivityResult);
        }).catch(err => {
            PromptActions.displayFriendlyErrorPrompt(err);
        }).finally();
    }

    const onToggleFiles = () => {
        if (isActiveHeadPost) {
            setIsActiveHeadPost(false);
        } else {
            setActiveReply({ replyId: props.discussionResult.headPost.discussionId });
        }
    }

    const sortReplies = () => {
        if (props.discussionResult.replies) {
            return ([...props.discussionResult.replies].sort(
                function (a, b) {
                    if (a && b) {
                        if (props.replySortOrder == ReplySortOrder.Acks) {
                            if (a.totalAcks < b.totalAcks) { return 1; }
                            if (a.totalAcks > b.totalAcks) { return -1; }
                            if (a.totalAcks == b.totalAcks) { return 0; }
                        } else if (props.replySortOrder == ReplySortOrder.OldestFirst) {
                            if (a.reply.lastModifiedDate > b.reply.lastModifiedDate) { return 1; }
                            if (a.reply.lastModifiedDate < b.reply.lastModifiedDate) { return -1; }
                        } else {
                            if (a.reply.lastModifiedDate > b.reply.lastModifiedDate) { return -1; }
                            if (a.reply.lastModifiedDate < b.reply.lastModifiedDate) { return 1; }
                        }
                    }
                    return 0;
                }
            ))
        }
        return [];
    }
    const [sortedReplies, setSortedReplies] = useState(sortReplies());

    useEffect(() => {
        if (activeReply.replyId == props.discussionResult.headPost.discussionId) {
            setIsActiveHeadPost(true);
        } else {
            setIsActiveHeadPost(false);
        }
    }, [activeReply]);

    useEffect(() => {
        if (displayDiscussionEdit) setIsActiveHeadPost(false);
    }, [displayDiscussionEdit]);

    useEffect(() => {
        setSortedReplies(sortReplies());
    }, [props.replySortOrder, props.discussionResult.replies]);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu, then close the menu
            if (showManageMenu && manageMenuRef.current && !manageMenuRef.current.contains(e.target)) {
                setShowManageMenu(false);
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [showManageMenu]);

    const resortReplies = () => {
        setSortedReplies(sortReplies());
    }

    const closeReportModal = () => {
        setShowReportModal(false);
    }

    const onReported = () => {
        if (props.refetch) {
            props.refetch();
        }
    }

    const onEditDiscussion = () => {
        setDisplayDiscussionEdit(true);
    }

    const onCloseDiscussionEdit = () => {
        setDisplayDiscussionEdit(false);
    }

    const onDeleteModalConfirm = () => {
        modifyForumDiscussionState(false, isOpen);
        props.onCancel();
    }

    const onDeleteModalClose = (e) => {
        setDisplayDeleteModal(false);
    }

    const onCloseDiscussion = (e) => {
        modifyForumDiscussionState(isEnabled, false);
    }

    const onOpenDiscussion = (e) => {
        modifyForumDiscussionState(isEnabled, true);
    }

    const onDeleteDiscussion = (e) => {
        setDisplayDeleteModal(true);
    }

    const onReportDiscussion = (e) => {
        // show report modal
        setShowReportModal(true);
    }

    const modifyForumDiscussionState = (newIsEnabled, newIsOpen) => {
        updateForumDiscussionState({
            variables: {
                discussionId: props.discussionResult.discussion.discussionId,
                isEnabled: newIsEnabled,
                isOpen: newIsOpen
            } as FORUM_UPDATE_DISCUSSION_STATEVariables
        }).then((saveDiscussionResult) => {
            // update frontend state
            setIsOpen(newIsOpen);
            setIsEnabled(newIsEnabled);

        }).catch(err => {
            PromptActions.displayFriendlyErrorPrompt(err);
        }).finally();
    }

    const onChangeContent = (value, e) => {
        setContent(value);
    }

    const scrollToPostReply = () => {
        postReplyRef.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }

    const onClear = (e) => {
        e.preventDefault();
        clearPostForm();
    }

    const clearPostForm = () => {
        rte.current?.clear();
        setContent("");
        setAssociations(null);
    }

    const onFilesChanged = (files: any[], links: any[]) => {
        let validFiles = _.filter(files,(file) => file.error === undefined);
        setAssociations(AssociationUtils.getAssociationInputs(validFiles, links));
    }

    const onSaveNewReply = (e) => {
        e.preventDefault();

        if (isSaveEnabled) {
            saveForumReply({
                variables: {
                    discussionId: props.discussionResult.discussion.discussionId,
                    discussionPostVersion: props.discussionResult.headPost.draftVersion,
                    content: content,
                    associations: associations,
                } as FORUM_SAVE_REPLY_MUTATIONVariables
            }).then((savedDiscussion) => {
                const errors = savedDiscussion?.data?.saveDiscussion?.errors;

                if (errors && errors.length > 0) {
                    throw errors[0];
                }

                // add reply to replies state
                var discussionResultNew = {
                    ...props.discussionResult,
                    replies: [...props.discussionResult.replies, savedDiscussion.data.saveForumReply]
                };
                setNewReplyId(savedDiscussion.data.saveForumReply.reply.replyId);
                props.onSelectDiscussion(discussionResultNew, props.topicResult);
            }).catch(err => {
                PromptActions.displayFriendlyErrorPrompt(err);
            }).finally();

            // clear form
            clearPostForm();
        }
    }

    const contentDivRef = React.useRef<HTMLDivElement>(null);

    const getSelection = (): string | null => {
        let selection = TagUtils.getSelectionTextAndContainerElement('div', 'discussion-text');
        
        if(!Boolean(selection.text) || contentDivRef.current !== selection.containerElement) {
            // show how-to-quote message
            setShowHowToQuote(true);       
            return null;
        }

        return selection.text;
    }

    const onCloseHowToQuote = (e) => {
        setShowHowToQuote(false);
    }
        
    scrollToTop();    

    return (
        <div className="row">
            <div className="small-16 large-10 column forum-bar">
                <div className="forum-discussions discussion-view">
                    <div className="no-mobile"><ReactTooltip place="right" backgroundColor="#738089" className="hover-text" /></div>
                    <div>
                        <ConfirmationModal displayModal={displayDeleteModal} heading="Delete discussion"
                            reasonMessage="Reason for deleting this discussion"
                            confirmMessage="Are you sure you want to delete this discussion?"
                            userRights={props.discussionResult.userRights} title={props.discussionResult?.headPost.subject}
                            onConfirm={onDeleteModalConfirm} onRequestClose={onDeleteModalClose} />

                        <ForumTabs postToDiscussion={scrollToPostReply} title={props.topicResult?.topic.title} />

                        <div className="thread">
                            <div className="back">
                                <a onClick={props.onCancel}>&lt; Discussion home</a>
                            </div>
                        </div>

                            <div ref={headPostRef} className={`thread fn-service-border-colour${isActiveHeadPost ? ' active-post' : ''}`}>
                                {displayDiscussionEdit &&
                                    <UpdateDiscussion onCancel={onCloseDiscussionEdit} forumId={props.discussionResult.discussion.forumId}
                                        discussionResult={props.discussionResult} onSelectDiscussion={props.onSelectDiscussion}
                                        topicResult={props.topicResult} topicResults={props.topicResults} groupId={props.groupId}
                                        onClick={props.onClick} />
                                }
                                {!displayDiscussionEdit &&
                                    <div className="fn-flex">
                                        <div className="ProfileInfo">
                                            <a href={`/viewprofile?Id=${props.discussionResult.createdBy.id}`} className="thumb">
                                                <img className="thumb" alt={props.discussionResult.createdBy.name} src={`/api/v1/image?id=${props.discussionResult.createdBy.avatarId}&amp;width=54&amp;height=54`} />
                                            </a>
                                        </div>
                                        <div className="form-horizontal discussion-heading">
                                            <div className="row full-width">
                                                <div className="small-16 columns">
                                                    <h2 dir="auto">{props.discussionResult.headPost.subject ?? <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />}
                                                        {!isOpen &&
                                                            <span> (Closed)</span>
                                                        }
                                                        {(!isEnabled && isReported) &&
                                                            <span> (Hidden)</span>
                                                        }
                                                        {(!isEnabled && !isReported) &&
                                                            <span> (Deleted)</span>
                                                        }</h2>
                                                </div>
                                                <div className="small-8 large-5 columns">
                                                    <a href={`/viewprofile?Id=${props.discussionResult.createdBy.id}`}>
                                                        {props.discussionResult.createdBy.name}
                                                    </a>
                                                </div>
                                                <div className="small-8 large-5 columns">
                                                    {renderDateTime(moment(props.discussionResult.discussion.lastModifiedDate))}{editedText}
                                                </div>
                                                <div className="small-6 large-3 columns">
                                                    {props.discussionResult?.totalRepliesCount} posts
                                                </div>
                                                <div className="small-8 large-3 columns">
                                                    <strong>{props.discussionResult?.unreadRepliesCount} new posts</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="discussion-text small-13 columns" ref={contentDivRef}>
                                    <div dir="auto"><span dangerouslySetInnerHTML={{ __html: props.discussionResult.headPost.content }} /></div>
                                </div>
                            {isEnabled &&
                                <div className="fn-flex discussion-actions">
                                    <div className="row full-width">
                                        <div className="small-2 columns">
                                            {props.discussionResult.discussion.isOpen &&
                                                <AckButton discussionResult={props.discussionResult} replyResult={undefined} setDiscussion={props.setDiscussion} />
                                            }
                                            {!props.discussionResult.discussion.isOpen &&
                                                <span>&nbsp;</span>
                                            }
                                        </div>
                                        <div className="small-3 columns">
                                            {props.discussionResult?.totalAcks}  {props.discussionResult?.totalAcks == 1 ? " Ack" : " Acks"}
                                        </div>
                                        <div className="small-5 large-5 columns no-mobile">
                                            {props.discussionResult.discussion.isOpen &&
                                                <QuoteButton getSelection={getSelection} rte={rte} discussionResult={props.discussionResult} />
                                            }
                                            {!props.discussionResult.discussion.isOpen &&
                                                <span>&nbsp;</span>
                                            }
                                        </div>
                                        <div className="small-2 large-1 columns" data-tip="Bookmark this discussion">
                                            <BookmarkToggle width={20} height={20}
                                                bookmarkId={bookmark?.userBookmarkId}
                                                discussionResult={props.discussionResult}
                                                groupId={props.groupId} />
                                        </div>
                                        {(props.discussionResult.userRights != UserRightsType.None || props.discussionResult.canReport) &&
                                            <div className="small-6 large-2 columns">
                                                { showReportModal &&
                                                <ReportItem key="reportmodal" itemType='Discussion' itemId={props.discussionResult.discussion.discussionId}
                                                    itemName={props.discussionResult.createdBy.name} onRequestsClose={closeReportModal} onReported={onReported}/>
                                                }
                                                <div onClick={onClickManageMenu} className="toggle">Manage
                                                    <span className="fn-icon fn-icon-arrow-down"></span>
                                                    <ul ref={manageMenuRef} className={`vertical-menu menu-toggle ${showManageMenu ? 'active' : 'inactive'}`}>
                                                    { props.discussionResult.canReport && <li><a onClick={onReportDiscussion}><SvgIcon icon="icon-report" /> Report discussion</a></li>
                                                    }
                                                        {isOpen && props.discussionResult.userRights != UserRightsType.None &&
                                                            <li><a onClick={onEditDiscussion}><SvgIcon icon="icon-edit" /> Edit post</a></li>
                                                        }
                                                        {!isOpen && props.discussionResult.userRights != UserRightsType.None &&
                                                            <li className="disabled" title="Open this discussion to enable editing"><SvgIcon icon="icon-edit" /> Edit post</li>
                                                        }
                                                        { props.discussionResult.userRights != UserRightsType.None &&
                                                            <li><a onClick={onDeleteDiscussion}><SvgIcon icon="icon-bin" /> Delete discussion</a></li>
                                                        }
                                                        {isOpen && props.discussionResult.userRights != UserRightsType.None &&
                                                            <li><a onClick={onCloseDiscussion}><SvgIcon icon="icon-cross" /> Close discussion</a></li>
                                                        }
                                                        {!isOpen && props.discussionResult.userRights != UserRightsType.None &&
                                                            <li><a onClick={onOpenDiscussion}><SvgIcon icon="icon-bubble" /> Open discussion</a></li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                        
                                        <div className="small-2 large-2 columns">
                                            {props.discussionResult && props.discussionResult.associations && props.discussionResult.associations.length > 0 &&
                                                <span className={`float-right${isActiveHeadPost ? ' fn-service-colour' : ''}`} onClick={onToggleFiles} data-tip="View related files">
                                                    <SvgIcon icon="icon-folder" className="fn-icon-large fn-icon-top-align" />
                                                    <SvgIcon icon="icon-arrow-left" className="fn-icon-large fn-icon-space no-mobile" />
                                                { isActiveHeadPost 
                                                    ? <SvgIcon icon="icon-arrow-up" className="fn-icon-space mobile-only" />
                                                    : <SvgIcon icon="icon-arrow-down" className="fn-icon-space mobile-only" />
                                                }                                               
                                                </span>
                                            }
                                        </div>
                                    </div>

                                
                                    { showHowToQuote &&
                                        <div className="ui-tooltip ui-tooltip-wide">
                                            <button className="button close" onClick={onCloseHowToQuote}><SvgIcon icon="icon-delete" /></button>
                                            In the post above, please select the text you want to quote then press
                                            <QuoteButton getSelection={getSelection} rte={rte} discussionResult={props.discussionResult} />
                                        </div>
                                    }
                                </div>
                            }
                        
                            { isActiveHeadPost &&
                            <div className="fade-in">
                                <div className="related-files">
                                {props.discussionResult && props.discussionResult.associations && props.discussionResult.associations.length > 0 &&
                                    <div className="fn-section-right fn-service-border-colour">
                                        <div className="form-horizontal fn-flex">
                                            <div className="row full-width">
                                                <div className="small-16 column">
                                                    <div className="fn-title">
                                                        <span>Related files</span>
                                                        </div>
                                                <div className="fn-section-header">
                                                    <span> {props.discussionResult.createdBy?.name}</span>
                                                                    <span className="fn-float-right">{renderDateTime(moment(props.discussionResult.lastActivityDate))}</span>
                                                </div>
                                                <div className="fn-section-body">
                                                    <DisplayFileList associations={props.discussionResult.associations} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                        }
                            </div>
                        { sortedReplies.length > 0 &&
                            <div className="discussion-comments">
                                <SortReplies sortList={ReplySortOrder} onChangeSortOrder={props.onChangeReplySortOrder}
                                    sortOrder={props.replySortOrder} />

                                {
                                    sortedReplies.map(
                                        (discussionReplyResult, index) =>
                                            <DiscussionReply
                                                newReplyId={newReplyId} key={discussionReplyResult.reply.replyId}
                                                setDiscussion={props.setDiscussion} rte={rte}
                                                highlightedReply={highlightedReply} setHighlightedReply={setHighlitedReply}
                                                activeReply={activeReply} setActiveReply={setActiveReply}
                                                discussionResult={props.discussionResult} discussionReplyResult={discussionReplyResult}
                                                groupId={props.groupId} onClick={props.onClick} resortReplies={resortReplies}
                                                title={props.discussionResult.headPost.subject}
                                                refetchDiscussion={props.refetch} />
                                    )
                                }
                            </div>
                        }

                        <div className="thread">
                            {(isOpen && isEnabled) &&
                                <div ref={postReplyRef} className="form-fields scroll-to">
                                    <div>
                                        <div className="fn-flex">
                                            <div className="avatar fn-flex-align-center">
                                                <img src={AppDelegate.config.userAvatar.urlWithSize(37, 37)} alt="My avatar" />
                                            </div>
                                            <div className="row full-width">
                                                <div className="small-16 large-9 columns">
                                                    <span className="title">{AppDelegate.config.userFullName}</span>
                                                </div>
                                                <div className="small-16 large-7 columns">
                                                    <span><Clock /></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-field">
                                            <RichTextEditor ref={rte} value={content} onValueChange={onChangeContent} userPreference={userPreference}
                                                readOnly={false} spellcheck={true} mode={RichTextEditorMode.ActivityPost} />
                                        </div>
                                        {(associations && associations.length > 0) &&
                                            <div className="form-field">
                                                <DisplayFileList associations={associations} />
                                            </div>
                                        }
                                        <div className="buttons">
                                            <ForumUpload associations={associations} _onFilesChanged={onFilesChanged} groupId={props.groupId}     >
                                            </ForumUpload>
                                            &nbsp;
                                            <button className="btn btn-default" onClick={onClear}>Cancel</button>
                                            &nbsp;
                                            <button className="btn btn-primary" disabled={!isSaveEnabled} onClick={onSaveNewReply}>Post</button>
                                        </div>
                                    </div>
                                </div>
                            }
                            {!isOpen &&
                                <div ref={postReplyRef} className="form-fields">
                                    <h4>This discussion is closed.</h4>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="small-16 large-6 columns flexColumn">
            </div>
        </div>
    )
}
