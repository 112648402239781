import { FileType } from "./Enums";

// See    ForceNet.Business.Model.Files.FileHeader
export default class FileHeader {

    id: string;
    name: string;
    type: FileType;

    constructor(id: string, name: string, type: FileType) {
        this.id = id;
        this.name = name;
        this.type = type;
    }

}

