import React, {
    FC,
    MouseEventHandler,
} from "react";
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';

import { association } from "../../../../types/graphqlTypes";
import { AssociationType } from "../model/Enums";

interface IDisplayFileListProps {
    associations: association[] | null,
    onRemoveLink?: (link: any, e: any) => MouseEventHandler<HTMLAnchorElement>,
}

export const DisplayFileList: FC<IDisplayFileListProps> = (props: IDisplayFileListProps) => {

    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <ul className="related-files">
            {_.map(props.associations, (association, i: number) => {
                let iconType: string;
                let href: string = "/services/DownloadFile.ashx?FileId=" + association.associatedItemId;

                if (association.associationType == AssociationType.GroupMedia
                    || association.associationType == AssociationType.AttachedPhoto) {
                    iconType = "image";
                } else if (association.associationType == AssociationType.GroupDocument
                    || association.associationType == AssociationType.AttachedFile) {
                    iconType = "document";
                } else if (association.associationType == AssociationType.EmbedlyResult) {
                    iconType = "document";
                } else {
                    iconType = "folder";
                    href = "/community/viewgroup?Id=" + searchParams.get('Id') + "&View=Files&FolderId=" + association.associatedItemId + "&Category=Document";
                }

                return <li key={association.associatedItemId}>
                    <a href={href}>
                        <span className={`fn-icon fn-icon-${iconType}`}></span>
                        <span className="title"> {association.displayName} </span>
                        {/*
                                <a href="#" className="fn-icon fn-icon-delete" onClick={() => props.onRemoveLink(association, this)}>
                                <span className="sr-only">Remove</span></a>
                            */}
                        <span className="description">{association.description}</span>
                    </a>
                </li>;
            })}

        </ul>
    );
}

export default DisplayFileList;