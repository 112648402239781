
import React, {
    FC,
    MouseEventHandler,
    useEffect,
    useState,
    useCallback,
} from "react";
import { createSearchParams, useSearchParams, useNavigate } from 'react-router-dom'

import LatestBookmarks from "../bookmarks/LatestBookmarks";
import Topics from "./components/Topics";
import { ViewTopic } from "./components/ViewTopic";
import { ViewDiscussion } from "./components/ViewDiscussion";
import { Sort } from "./components/Sort";

import "./ForumsModule.css";

import { Discussions } from "./components/Discussions";
import { UpdateDiscussion } from "./components/UpdateDiscussion";
import ForumTabs from "./components/ForumTabs";
import RelatedFiles from "./components/RelatedFiles";

import {
    useLazyQuery,
} from "@apollo/client";

const forumsGql = require('../../../gql/forums.gql');

import {
    forumResult,
    FORUM_GET_FORUM_QUERYVariables,
    forumDiscussionResult,
    forumResult_topics,
    FORUM_GET_DISCUSSION_DETAIL_QUERYVariables,
    FORUM_GET_DISCUSSIONS_QUERYVariables,
} from "../../../types/graphqlTypes";

import { ForumSortOrder } from "./model/ForumSortOrder";
import { ReplySortOrder } from "./model/ReplySortOrder";
import GroupTabsConstants from '../../core/constants/GroupTabsConstants';
import { TopicAdmin } from "./components/TopicAdmin";

interface IForumsModuleProps {
    forumId: string;
    groupId: string;
    isTopicAdmin: boolean;
    className: string | null;
}

enum ViewMode {
    Loading,
    Main,
    ViewTopic,
    ViewDiscussion,
    NewDiscussion
}

interface IForceLoadDiscussionParams {
    discussionId: string;
    replyId: string | null;
    isReviewing: boolean;
}

export const ForumsModule: FC<IForumsModuleProps> = (props: IForumsModuleProps) => {
    const [getForumResult, setForumResult] = useState<forumResult>();
    const [forumDiscussionResults, setForumDiscussionResults] = useState<forumDiscussionResult[]>([]);
    const [topicResultsState, setTopicResultsState] = useState<forumResult_topics[]>([]);
    const [currentTopic, setCurrentTopic] = useState<forumResult_topics>();
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Loading);
    const [sortOrder, setSortOrder] = useState<ForumSortOrder>(sessionStorage.getItem("topics-sort") ?? ForumSortOrder.Topic);
    const [replySortOrder, setReplySortOrder] = useState<ReplySortOrder>(sessionStorage.getItem("reply-sort") ?? ReplySortOrder.MostRecent);
    const [currentDiscussion, setDiscussion] = useState<forumDiscussionResult>();
    var visibleTopics = topicResultsState;
    const [forceLoadDiscussion, setForceLoadDiscussion] = useState<IForceLoadDiscussionParams | null>();

    // load forum data
    const [getForum, { loading, data, error, networkStatus, refetch }] = useLazyQuery(forumsGql.FORUM_GET_FORUM_QUERY, {
        fetchPolicy: "network-only",
        onCompleted: data => {
            setForumResult(data.getForum);
            setForumDiscussionResults(data.getForum.discussions);
            setTopicResultsState(data.getForum.topics?.filter(topicResult => topicResult.topic?.isEnabled));
        }
    });

    // load a specific discussion
    const [getDiscussion, { loading: dloading, data:ddata, error: derror, networkStatus: dnetworkStatus }] = useLazyQuery(forumsGql.FORUM_GET_DISCUSSION_DETAIL_QUERY, {
        fetchPolicy: "network-only",
        onCompleted: ddata => {
            setDiscussion(ddata.getForumDiscussionDetail);
            let selectedTopic = topicResultsState.find(t => t.topic.topicId == ddata.getForumDiscussionDetail.topic.topicId);
            if (selectedTopic) setCurrentTopic(selectedTopic);
            setViewMode(ViewMode.ViewDiscussion);
        }
    });    

    // load all  discussions for a topic
    const [getForumDiscussions, { loading: tloading, data:tdata, error: terror, networkStatus: tnetworkStatus }] = useLazyQuery(forumsGql.FORUM_GET_DISCUSSIONS_QUERY, {
        fetchPolicy: "network-only",
        onCompleted: tdata => {
            setForumDiscussionResults(tdata.getForumDiscussions.discussions);
            setViewMode(ViewMode.ViewTopic);
        }
    });    

    const variables: FORUM_GET_FORUM_QUERYVariables = {
        forumId: props.forumId,
    }

    const refresh = useCallback(() => {
        getForum({
            variables
        });
    }, []);

    useEffect(() => {
        getForum({
            variables
        });
    }, []);

    useEffect(() => {
        if(forceLoadDiscussion) {   
            setViewMode(ViewMode.Loading);

            getDiscussion(
                { 
                    variables:{
                        discussionId: forceLoadDiscussion.discussionId,
                        replyId: forceLoadDiscussion.replyId,
                        isReviewing: forceLoadDiscussion.isReviewing
                    } as FORUM_GET_DISCUSSION_DETAIL_QUERYVariables
                });
        } else {
            setViewMode(ViewMode.Main);
        }
    }, [forceLoadDiscussion]);

    useEffect(() => {
        if(viewMode == ViewMode.Loading && currentTopic != null) {   
            getForumDiscussions(
                { 
                    variables:{
                        forumId: props.forumId,
                        topicId: currentTopic.topic?.topicId,
                    } as FORUM_GET_DISCUSSIONS_QUERYVariables
                });            
        }
    }, [currentTopic, viewMode]);

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const rebuildUrlParams = () => {
        const newParams: { [key: string]: string } = {};
        searchParams.forEach((value: string, key: string) => {
            newParams[key] = value;
        });

        setSearchParams(newParams);
        navigate({
            search: createSearchParams(newParams).toString(),
        }, { replace: true });
    }

    useEffect(() => {
        if (topicResultsState && searchParams.has(GroupTabsConstants.DISCUSSION_ID_PARAM)) {
            const token = searchParams.get(GroupTabsConstants.DISCUSSION_ID_PARAM);

            if (token) {
                let savedDiscussion = sessionStorage.getItem(GroupTabsConstants.DISCUSSION_RESULT_STORAGE) ? JSON.parse(sessionStorage.getItem(GroupTabsConstants.DISCUSSION_RESULT_STORAGE)) as forumDiscussionResult : null;
                let isReviewing = searchParams.has(GroupTabsConstants.IS_REVIEWING_PARAM);
                let replyId = searchParams.has(GroupTabsConstants.FORUM_REPLY_ID_PARAM)
                    ? searchParams.get(GroupTabsConstants.FORUM_REPLY_ID_PARAM)
                    : null;

                if (savedDiscussion?.discussion?.discussionId == token && !isReviewing) {
                    setDiscussion(savedDiscussion);
                    let selectedTopic = topicResultsState.find(t => t.topic.topicId == savedDiscussion.topic.topicId);
                    if (selectedTopic) setCurrentTopic(selectedTopic);
                    setViewMode(ViewMode.ViewDiscussion);
                } else {
                    setForceLoadDiscussion({
                        discussionId: token,
                        isReviewing: isReviewing,
                        replyId: replyId
                    });
                }
                searchParams.delete(GroupTabsConstants.DISCUSSION_ID_PARAM);
            }
        }

        if (searchParams.has(GroupTabsConstants.VIEW_PARAM)) {
            searchParams.delete(GroupTabsConstants.VIEW_PARAM);
        }

        rebuildUrlParams();

        if (topicResultsState && topicResultsState.length > 0 && getVisibleTopics().length < 2) {
            setSortOrder(ForumSortOrder.DateUpdated);
        } else {
            setSortOrder(ForumSortOrder.Topic);
        }
        sessionStorage.setItem("topics-sort", sortOrder);
    }, [topicResultsState]);

    const getVisibleTopics = () => {
        return topicResultsState.filter(t => t.totalDiscussionCount > 0);
    }

    const sortTopicsAlphabetically = (topicsToSort) => {
        return [...topicsToSort].sort(
            function (a, b) {
                if (a.topic && b.topic) {
                    if (a.topic?.title < b.topic?.title) { return -1; }
                    if (a.topic?.title > b.topic?.title) { return 1; }
                }
                return 0;
            }
        );
    }

    let sortedTopics = topicResultsState && topicResultsState.length > 0 ? sortTopicsAlphabetically(topicResultsState) : null;
    let sortedVisibleTopics = topicResultsState && topicResultsState.length > 0 ? sortTopicsAlphabetically(getVisibleTopics()) : null;

    const updateTopicResults = (topicResults) => {
        setTopicResultsState(topicResults.filter(topicResult => topicResult.topic?.isEnabled));
    }

    const onChangeSortOrder = (e) => {
        setSortOrder(e.target.value);
        sessionStorage.setItem("topics-sort", e.target.value);
    }
    const onChangeReplySortOrder = (e) => {
        setReplySortOrder(e.target.getAttribute("rel"));
        sessionStorage.setItem("reply-sort", e.target.getAttribute("rel"));
    }
    const onSelectTopic = (topicResult: forumResult_topics) => {
        setCurrentTopic(topicResult);
        setViewMode(ViewMode.Loading);
    }
    const onSelectDiscussion = (discussionResult: forumDiscussionResult, topicResult: forumResult_topics) => {
        setDiscussion(discussionResult);
        setCurrentTopic(topicResult);
        setViewMode(ViewMode.ViewDiscussion);
    }

    const newDiscussion = () => {
        setDiscussion(null);
        setViewMode(ViewMode.NewDiscussion);
    }

    const returnToMain = () => {
        setDiscussion(null);
        setViewMode(ViewMode.Main);
        setSortOrder(visibleTopics.length > 1 ? ForumSortOrder.Topic : ForumSortOrder.DateUpdated);
        sessionStorage.setItem("topics-sort", sortOrder);
        setCurrentTopic(null);
        refresh();
    }

    const refetchDiscussion = () => {
        setViewMode(ViewMode.Loading);

        getDiscussion({ 
            variables:{
                discussionId: currentDiscussion?.discussion.discussionId,
                replyId: null,
                isReviewing: false
            } as FORUM_GET_DISCUSSION_DETAIL_QUERYVariables
        });
    }

    function onShowModal(e): MouseEventHandler<HTMLAnchorElement> {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        return e;
    }

    return (<div>
        {viewMode == ViewMode.Loading &&
            <div className="spinner"></div>
        }
        {viewMode == ViewMode.Main &&
            <div className="row">
                <div className="small-16 large-10 column forum-bar">
                    <div className="forum-discussions">
                        <ForumTabs newDiscussion={newDiscussion} />

                        <div className="actions">
                            <Sort topicsEnabled={topicResultsState && visibleTopics.length > 1} sortList={ForumSortOrder} onChangeSortOrder={onChangeSortOrder} sortOrder={sortOrder} />
                        </div>

                        {sortOrder == ForumSortOrder.Topic
                            ? <Topics onSelectDiscussion={onSelectDiscussion} onSelectTopic={onSelectTopic} onCancel={returnToMain}
                                forumId={props.forumId} discussionResults={forumDiscussionResults} topicResults={sortedVisibleTopics} />
                            : <Discussions onSelectDiscussion={onSelectDiscussion} forumId={props.forumId}
                                onSelectTopic={onSelectTopic} discussionResults={forumDiscussionResults} topic={currentTopic} showTopic={true}
                                sortOrder={sortOrder} discussionsFilter={{ open: true, closed: false }} />
                        }
                    </div>
                </div>
                <div className="small-16 large-6 columns flexColumn">
                    {props.isTopicAdmin ?
                        <TopicAdmin forumId={props.forumId} topicResults={sortedTopics} updateTopicResults={updateTopicResults} />
                    : null }
                    <LatestBookmarks groupId={props.groupId} />
                </div>
            </div>
        }
        {viewMode == ViewMode.ViewTopic &&
            <div className="row">
                <div className="small-16 large-10 column forum-bar">
                    <div className="forum-discussions">
                        <ViewTopic onSelectDiscussion={onSelectDiscussion} onSelectTopic={onSelectTopic} onCancel={returnToMain}
                            forumId={props.forumId} topicResult={currentTopic} discussionResults={forumDiscussionResults}
                            newDiscussion={newDiscussion} sortOrder={sortOrder} onChangeSortOrder={onChangeSortOrder} />
                    </div>
                </div>
                <div className="small-16 large-6 columns flexColumn">
                    <LatestBookmarks groupId={props.groupId} />
                </div>
            </div>
        }
        {viewMode == ViewMode.ViewDiscussion &&
            <ViewDiscussion onSelectDiscussion={onSelectDiscussion} onCancel={returnToMain}
                topicResult={currentTopic} discussionResult={currentDiscussion} setDiscussion={setDiscussion}
                replySortOrder={replySortOrder} onChangeReplySortOrder={onChangeReplySortOrder}
                topicResults={topicResultsState} groupId={props.groupId}
                refetch={refetchDiscussion}
                onClick={onShowModal} />
        }
        {viewMode == ViewMode.NewDiscussion &&
            <div className="row">
                <div className="small-16 large-10 column forum-bar">
                    <div className="forum-discussions">
                        <div className="new-discussion">
                            <div className="back">
                                <a onClick={returnToMain}>&lt; Discussion home</a>
                            </div>
                            <h2>Start a new discussion</h2>
                            <UpdateDiscussion onCancel={returnToMain} forumId={props.forumId} topicResult={currentTopic}
                                topicResults={sortedTopics} groupId={props.groupId} discussionResult={currentDiscussion}
                                onClick={onShowModal} />
                        </div>
                    </div>
                </div>
                <div className="small-16 large-6 columns flexColumn">
                </div>
            </div>
        }
    </div>)
}

export default ForumsModule;